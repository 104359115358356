.btn-tooltip {
  width: 20px;
  height: 20px;
  padding: 0px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 1 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  color: #ffffff !important;
}

.spacerobot-tooltip {
  span {
    font-size: 12px !important;
    line-height: normal !important;
  }
}

@media (min-width: 576px) {
  .btn-tooltip {
    width: 36px;
    height: 36px;
    border-radius: 18px !important;
    font-weight: 600 !important;
    font-size: 28px !important;
  }
  
  .spacerobot-tooltip {
    span {
      font-size: 14px !important;
    }
  }
}  