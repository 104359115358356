.framed-address {
  width: 336px;
  height: 66px;
  border: 5px solid #DEA30B;
  box-sizing: border-box;
  border-radius: 10px;
  color: #FFFFFF;
}

.framed-address span {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;

  text-align: center;
  letter-spacing: 0.05em;
}

.framed-address a {
  color: #FFFFFF;
}

.framed-address a:hover {
  color: #FFFFFF;
}