a.button {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 27px !important;
  line-height: 32px !important;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: #FFFFFF;

  background-color: #DEA30B;
  border-radius: 5px;
  padding: 14px 50px;
  font-size: 28px;
  line-height: 29px;
}

a.button:hover {
	background-color: #f8c02f;
  color: #FFFFFF;
  transition-delay: 150ms;
  -webkit-transition: all 500ms ease;
}

@media all and (max-width: 992px) {
  a.button {
    padding: 15px 40px;
  }
}
@media all and (max-width: 576px) {
  a.button {
    padding: 10px 25px;
    font-size: 20px !important;
  }
}

.disabled-link {
  pointer-events: none;
  opacity: 0.1;
}