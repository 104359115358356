.select-robopack {
    height: 316px !important;
}

@media all and (min-width: 576px) {
    .select-robopack {
        height: 600px !important;
    }
}

@media all and (min-width: 768px) {
    .select-robopack {
        height: 700px !important;
    }
}

@media all and (min-width: 992px) {
    .select-robopack {
        height: 900px !important;
    }
}