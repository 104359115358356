.templates {
    background: rgba(55, 82, 102, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    .space-robot {
        .image {
            width: 110px;
            height: 110px;
        }
        .remove-icon {
            position: absolute;
            max-width: 20px;
            max-height: 20px;
            top: -6px;
            right: -6px;
            z-index: 1001  
        }
        .download-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #DEA30B;
            border-radius: 50%;
            color: white;
            position: absolute;
            top: -6px;
            left: -6px;
            z-index: 1001;
            width: 20px;
            height: 20px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
    .title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    .button-back-upgrade {
        width: 117px;
        height: 11px;
        font-size: 12px !important;
        line-height: 14px;
        padding: 3px 8px;
        border-radius: 2px;
    }
}


@media (min-width: 576px) {
    .templates {
        background: transparent;
        box-shadow: 0px 0px 0px;
        border-radius: 0px;
        .space-robot {
            .image {
                width: 450px;
                height: 450px;
            }
            .remove-icon {
                position: absolute;
                top: 20px;
                right: 28px;
                max-width: 54px;
                max-height: 54px;
            }
            .download-icon {
                position: absolute;
                padding: 10px;
                top: 20px;
                left: 28px;
                width: 50px;
                height: 50px;

                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .title {
            font-size: 28px;
            line-height: 38px;
        }
    }
}