.pack {
  position: "relative" !important;
  display: "inline-block" !important;
  
  img {
    max-width: 160px;
    max-height: 160px;
  }

  .overlay {
    width: 195px;
    height: 195px;
    background: rgba(23, 23, 23, 0.7);
    border-radius: 20px;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media all and (min-width: 480px) {
  .pack img {
    max-width: 195px;
    max-height: 195px;
  }
}

