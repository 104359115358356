.tags {
    p {
        font-size: 8px !important;
    }
    img {
        height: 42px;
    } 
    .selected {
        border-bottom: 2px solid #FFFFFF;
    }
}

@media (min-width: 576px) {
    .tags {
        p {
            font-size: 18px !important;
        }
        img {
            height: 104px;
        }
    }
}