.robopack {
  h2 {
    color: #FFC225;
  }

  @media all and (max-width: 450px) {
    img {
      max-width: 308px;
    }
  }

  @media all and (max-width: 380px) {
    img {
      max-width: 280px;
    }
  }
}
