.space-robots {
    .form-check-input:checked {
        background-color: rgb(222, 163, 10);
        border-color: rgb(231, 211, 162);
    }
    
    .form-switch .form-check-input {
        width: 43px !important;
        height: 13px !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(222, 163, 10)'/%3e%3c/svg%3e");
    }

    .form-switch .form-check-input:focus {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(231, 211, 162)'/%3e%3c/svg%3e");
    }
}

@media (min-width: 576px) {
    .space-robots {
        .form-switch .form-check-input {
            width: 130px !important;
            height: 39px !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(222, 163, 10)'/%3e%3c/svg%3e");
        }
    
        .form-switch .form-check-input:focus {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(231, 211, 162)'/%3e%3c/svg%3e");
        }
    }
}