.robopart {
    max-width: 164px;
    img {
        max-width: 164px;
        border-radius: 20px;
    }

    .overlay {
        background: rgba(23, 23, 23, 0.75);
        border-radius: 20px;
    
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
}

.robopart {
    p {
        font-size: 18px !important;
    }
}

a.upgrade {
    padding: 5px 22px;
    font-size: 24px !important;
}

@media all and (min-width: 390px) {
    .robopart {
        max-width: 240px;
        img {
            max-width: 240px;
            border-radius: 20px;
        }
    }
}

@media all and (min-width: 420px) {
    .robopart {
        max-width: 208px;
        img {
            max-width: 208px;
            border-radius: 20px;
        }
    }
}

@media all and (min-width: 460px) {
    .robopart {
        max-width: 248px;
        img {
            max-width: 248px;
            border-radius: 20px;
        }
    }
}

@media all and (min-width: 545px) {
    .robopart {
        max-width: 164px;
        img {
            max-width: 164px;
            border-radius: 20px;
        }
    }
}

@media all and (min-width: 768px) {
    .robopart {
        max-width: 248px;
        img {
            max-width: 248px;
            border-radius: 20px;
        }
        p {
            font-size: 22px !important;
        }
    }
}

@media all and (min-width: 992px) {
    .robopart {
        max-width: 280px;
        img {
            max-width: 280px;
            border-radius: 20px;
        }
    }
}

