.claim {
//   min-height: calc(100vh - 110px);
  padding: 15em 2em 0em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.claim p, span {
  max-width: 720px;
}

@media all and (max-width: 576px) {
  .claim {
    padding-top: 12em;
  }
}