.modal-dialog {
  font-style: normal;
}

.modal-header {
  background-color: transparent !important;
  color: #dea30b;
}

.dapp-modal-dialog-header,
.dapp-modal-dialog-content-body,
.dapp-core-component__main__text-center {
  background-color: transparent !important;
  color: white !important;
}

.dapp-modal-dialog-header {
  background-color: transparent !important;
  color: white;
}

.dapp-modal-dialog-content {
  padding: 20px;
  background: transparent !important;
  border: none;
}

.dapp-core-component__dappModalStyles__dappModal {
  background: black !important;
}

.dapp-core-component__dappModalStyles__dappModalCloseButton {
  top: 14px;
}

.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b28937'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat !important;
  opacity: 100 !important;
}

.modal-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #dea30b;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-close {
  margin: 0 !important;
}

.modal-content {
  padding: 20px;
  background: rgba(55, 82, 102, 0.9) !important;
  border-radius: 10px !important;
  border: 1px solid #dea30b;
  margin-right: 25px;
}

.connect-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connect-buttons .btn {
  background-color: transparent !important;
  color: white !important;
  padding: 14px !important;
  border-radius: 10px !important;
  width: 100%;
  margin: 0 !important;
  margin-bottom: 8px !important;
  border: 1px solid #dea30b;
  // text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

.dapp-core-ui-component .btn-primary:hover {
  color: #000000 !important;
}

.cls-black {
  fill: #242526;
}

.cls-cyan {
  fill: #23f7dd;
}

.dapp-core-ui-component .btn {
  margin: 0, 0, 0, 0 !important;
}

.dapp-ledger-login-container,
.dapp-wallet-connect-login-modal {
  z-index: 2000 !important;
  color: black !important;
}

.dapp-wallet-connect-login-modal.dapp-modal-dialog-wrapper {
  z-index: 1999 !important;
}
