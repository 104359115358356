.traits {
    svg {
        max-width: 15.31px;
        max-height: 15.31px;
    }
    .points {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }
    .red {
        color: #D42627;
    }
    .green {
        color: #7AD037;
    }
}

@media (min-width: 576px) {
    .traits {
        svg {
            max-width: 22.08px;
            max-height: 22.08px;
        }
        .points {
            font-size: 36px;
            line-height: 49px;
        }
    }
}