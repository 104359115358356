.robot-composition {
    .image {
        img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
        }
        img.overlayed {
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
}