.upgrade {
    background: rgba(55, 82, 102, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    .title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    .robot-slide {
        .image {
            width: 200px;
            height: 200px;
        }
    }
    
    .robopart-slide {
        .image {
            width: 150px;
            height: 150px;
            img {
                border-radius: 20px;
                width: 100%;
                height: 100%;
            }
        }
    }

    .navigate-icon {
        width: 25px;
        height: 25px;
    }

    
    .button-upgrade {
        width: 134px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 800;
        font-size: 14px !important;
        line-height: 17px;
        padding: 6px 0px;
        border-radius: 2px;
    }
    
    .button-lock {
        font-size: 16px !important;
        line-height: 19.2px !important;
        padding: 6px 28px;
        border-radius: 2px;
    }

    .not-found {
        position: relative;

        img {
            display: block;
            position: relative;
        }

        .button-buy {
            position: absolute;
            transform: translate(-50%,-50%);
            margin-right: -50%;
            top: 50%;
            left: 50%;
            z-index: 100;
        }

        a.button {
            padding: 10px 25px;
            font-size: 10px !important;
          }
    }

    
    .robopart-tag {
        .image {
            height: 44px !important;
            width: 44px !important;
            background: #171717;
            border-radius: 5px;
            padding: 6px;
        }
        
        .label {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 12px;            
        }
        .active {
            border: 0.75px solid #DEA30B;
        }
    }
    
    
    .traits {
        svg {
            max-width: 15.31px;
            max-height: 15.31px;
        }
        .points {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
        }
        .red {
            color: #D42627;
        }
        .green {
            color: #7AD037;
        }
    }
    
    .muted {
        opacity: 0.40;
    }
    
    .all-roboparts {
        span {
            font-size: 12px;
        }
    }
}

@media (min-width: 576px) {
    .upgrade {
        background: rgba(29, 32, 35, 0.5);
        .title {
            font-size: 28px;
            line-height: 38px;
        }
        .robot-slide {
            .image {
                width: 450px;
                height: 450px;
            }
        }
        .robopart-slide {
            .image {
                width: 450px;
                height: 450px;
            }
        }
        
        .robopart-tag {
            .image {
                height: 75px !important;
                width: 75px !important;
                background: #171717;
                border-radius: 5px;
                padding: 8px;
            }
            
            .label {
                font-size: 11px;
                line-height: 15px;
            }
        }
        .traits {
            svg {
                max-width: 22.08px;
                max-height: 22.08px;
            }
            .points {
                font-size: 36px;
                line-height: 49px;
            }
        }
        .button-lock {
            font-size: 18px !important;
            line-height: 22px !important;
            padding: 10px 40px;
            border-radius: 5px;
        }
        
        .all-roboparts {
            span {
                font-size: 28px;
            }
        }
        .button-upgrade {
            width: 336px;
            font-size: 27px !important;
            line-height: 32px;
            padding: 8px 0px;
            border-radius: 5px;
        }

        .not-found {
            position: relative;
    
            img {
                display: block;
                position: relative;
            }
    
            .button-buy {
                position: absolute;
                transform: translate(-50%,-50%);
                margin-right: -50%;
                top: 50%;
                left: 50%;
                z-index: 100;
                animation: 2s fadeIn;
            }

            a.button {
                padding: 10px 25px;
                font-size: 24px !important;
              }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}