.roboparts {
    //        width: 1370px;
    //        height: 900px;
            
    background: rgba(29, 32, 35, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    .not-found {
        position: relative;

        img {
            display: block;
            position: relative;
        }

        .button-buy {
            position: absolute;
            transform: translate(-50%,-50%);
            margin-right: -50%;
            top: 50%;
            left: 50%;
            z-index: 100;
        }

        a.button {
            padding: 10px 25px;
            font-size: 10px !important;
          }
    }
}

@media (min-width: 576px) {
    .roboparts {
        .not-found {
            position: relative;

            img {
                display: block;
                position: relative;
            }

            .button-buy {
                position: absolute;
                transform: translate(-50%,-50%);
                margin-right: -50%;
                top: 50%;
                left: 50%;
                z-index: 100;
                animation: 2s fadeIn;
            }

            a.button {
                padding: 10px 25px;
                font-size: 24px !important;
            }
        }
    }
}